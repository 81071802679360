import React from "react";
import "../styles/Skills.css";

const Skills = () => {
  return (
    <div className="skills-container">
      <div className="skills">
        <h1>
          <span class="tl1">s</span>
          <span class="tl2">k</span>
          <span class="tl3">i</span>
          <span class="tl4">l</span>
          <span class="tl5">l</span>
          <span class="tl6">s</span>
        </h1>
        <div className="fadeContainer">
          <div className="skill-item">
            <p>HTML / CSS / JavaScript</p>
            <div className="skill-bar">
              <span className="skill-level-beginner">beginner</span>
              <div className="skill-level-line" id="js-line"></div>
              <span className="skill-level-expert">expert</span>
            </div>
          </div>
          <br></br>
          <div className="skill-item">
            <p>React JS</p>
            <div className="skill-bar">
              <span className="skill-level-beginner">beginner</span>
              <div className="skill-level-line" id="react-line"></div>
              <span className="skill-level-expert">expert</span>
            </div>
          </div>
          <br></br>
          <div className="skill-item">
            <p>Wordpress</p>
            <div className="skill-bar">
              <span className="skill-level-beginner">beginner</span>
              <div className="skill-level-line" id="wp-line"></div>
              <span className="skill-level-expert">expert</span>
            </div>
          </div>
          <br></br>
          <div className="skill-item">
            <p>PHP / SQL</p>
            <div className="skill-bar">
              <span className="skill-level-beginner">beginner</span>
              <div className="skill-level-line" id="php-line"></div>
              <span className="skill-level-expert">expert</span>
            </div>
          </div>
          <br></br>
          <div className="skill-item">
            <p>Python</p>
            <div className="skill-bar">
              <span className="skill-level-beginner">beginner</span>
              <div className="skill-level-line" id="python-line"></div>
              <span className="skill-level-expert">expert</span>
            </div>
          </div>
          <br></br>
          <div className="skill-item">
            <p>Java</p>
            <div className="skill-bar">
              <span className="skill-level-beginner">beginner</span>
              <div className="skill-level-line" id="java-line"></div>
              <span className="skill-level-expert">expert</span>
            </div>
          </div>
          <br></br>
          <div className="skill-item">
            <p>C# / Unity</p>
            <div className="skill-bar">
              <span className="skill-level-beginner">beginner</span>
              <div className="skill-level-line" id="unity-line"></div>
              <span className="skill-level-expert">expert</span>
            </div>
          </div>
          <br></br>
          <div className="skill-item">
            <p>Visual programming / VVVV</p>
            <div className="skill-bar">
              <span className="skill-level-beginner">beginner</span>
              <div className="skill-level-line" id="vvvv-line"></div>
              <span className="skill-level-expert">expert</span>
            </div>
          </div>
          <br></br>

          <div className="skill-item">
            <p>MediaCreation / Adobe Creative Suite & Affinity</p>
            <div className="skill-bar">
              <span className="skill-level-beginner">beginner</span>
              <div className="skill-level-line" id="adobe-line"></div>
              <span className="skill-level-expert">expert</span>
            </div>
          </div>
          <br></br>
          <div className="skill-item">
            <p>Music Production / Logic pro, FL-Studio & Ableton</p>
            <div className="skill-bar">
              <span className="skill-level-beginner">beginner</span>
              <div className="skill-level-line" id="music-line"></div>
              <span className="skill-level-expert">expert</span>
            </div>
          </div>
          <br></br>
          <div className="skill-item">
            <p>3D Modelling / Cinema 4D & Blender</p>
            <div className="skill-bar">
              <span className="skill-level-beginner">beginner</span>
              <div className="skill-level-line" id="threed-line"></div>
              <span className="skill-level-expert">expert</span>
            </div>
          </div>
          <br></br>
          <div className="skill-item">
            <p>iWork / MS-Office</p>
            <div className="skill-bar">
              <span className="skill-level-beginner">beginner</span>
              <div className="skill-level-line" id="iwork-line"></div>
              <span className="skill-level-expert">expert</span>
            </div>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default Skills;
