import React from "react";
import flowerImage from "../assets/flower.png";
import "../styles/Contact.css";

const Contact = () => {
  return (
    <div className="contact-container">
      <div className="contact">
        <h1>
          <span className="tl1">c</span>
          <span className="tl2">o</span>
          <span className="tl3">n</span>
          <span className="tl4">t</span>
          <span className="tl5">a</span>
          <span className="tl6">c</span>
          <span className="tl7">t</span>
        </h1>
        <div className="fadeContainer">
          <p>
            Contact me for work inquiries or any questions. You can reach me via
            email at{" "}
            <a href="mailto:info@lucas.vi" className="la">
              <span className="clb1">i</span>
              <span className="clb">n</span>
              <span className="clb1">f</span>
              <span className="clb">o</span>
              <span className="clb1">@</span>
              <span className="clb">l</span>
              <span className="clb1">u</span>
              <span className="clb">c</span>
              <span className="clb1">a</span>
              <span className="clb">s</span>
              <span className="clb1">.</span>
              <span className="clb">v</span>
              <span className="clb1">g</span>
            </a>
            . Feel free to also connect with me on{" "}
            <a
              href="https://www.linkedin.com/in/1122lv/"
              target="_blank"
              className="la"
              rel="noreferrer"
            >
              <span className="clb">L</span>
              <span className="clb1">i</span>
              <span className="clb">n</span>
              <span className="clb1">k</span>
              <span className="clb">e</span>
              <span className="clb1">d</span>
              <span className="clb">I</span>
              <span className="clb1">n</span>
            </a>
            .
          </p>
          <img className="flower" src={flowerImage} alt="Flower" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
