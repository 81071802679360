import React from "react";
import "../styles/Work.css";

const Work = () => {
  return (
    <div className="work-container">
      <div className="work">
        <h1>
          <span class="tl1">w</span>
          <span class="tl2">o</span>
          <span class="tl3">r</span>
          <span class="tl4">k</span>
        </h1>
        <div className="fadeContainer">
          <p>Presenting a curated collection of my recent projects:</p>
          <p>
            {" "}
            <a href="https://acnw3.com" target="blank" className="la">
              <span class="lb">a</span>
              <span class="lb1">c</span>
              <span class="lb">n</span>
              <span class="lb1">w</span>
              <span class="lb">3</span>
              <span class="lb1">.</span>
              <span class="lb">c</span>
              <span class="lb1">o</span>
              <span class="lb">m</span>
            </a>
          </p>
          <div class="rounded-iframe">
            <iframe
              scrolling="no"
              src="https://acnw3.com"
              width="100%"
              /* height="40vh" */
              title="Webseiten-Vorschau"
            ></iframe>
          </div>

          <p>
            ACNW3 is a Berlin-based company specializing in Cloud native and
            Web3 technology. As an employee, I had the opportunity to develop
            their homepage using HTML, CSS, and various JavaScript libraries.
          </p>
          <br></br>

          <hr className="divider" />
          <br></br>
          <p>
            {" "}
            <a href="https://letteverein.berlin" target="blank" className="la">
              <span class="lb">l</span>
              <span class="lb1">e</span>
              <span class="lb">t</span>
              <span class="lb1">t</span>
              <span class="lb">e</span>
              <span class="lb1">v</span>
              <span class="lb">e</span>
              <span class="lb1">r</span>
              <span class="lb1">e</span>
              <span class="lb">i</span>
              <span class="lb1">n</span>
              <span class="lb">.</span>
              <span class="lb1">b</span>
              <span class="lb">e</span>
              <span class="lb1">r</span>
              <span class="lb">l</span>
              <span class="lb1">i</span>
              <span class="lb">n</span>
            </a>
          </p>
          <div class="rounded-iframe">
            <iframe
              scrolling="no"
              src="https://letteverein.berlin"
              width="100%"
              /* height="400px" */
              title="Webseiten-Vorschau"
            ></iframe>
            <br></br>
          </div>
          <p>
            During my vocational training at Lette Verein Berlin, I was
            fortunate to collaborate with my colleague Lukas U. in designing and
            developing the school's new homepage. A key requirement for the
            project was the utilization of Wordpress as a CMS, enabling staff
            members to effortlessly create and publish new posts.
          </p>
          <br></br>

          <hr className="divider" />
          <br></br>
          <p>
            {" "}
            <a
              href="https://latrabio.itch.io/ratlab"
              target="blank"
              className="la"
            >
              <span class="lb1">r</span>
              <span class="lb">a</span>
              <span class="lb1">t</span>
              <span class="lb">l</span>
              <span class="lb1">a</span>
              <span class="lb">b</span>
            </a>
          </p>
          {/* 
            <a href="https://latrabio.itch.io/ratlab" target="blank"> */}
          <img
            alt="https://latrabio.itch.io/ratlab"
            src={require("../assets/ratlab.png")}
          ></img>
          {/* </a> */}
          <p>
            As a proof of concept, my colleague Sven N. and I have created a
            compact maze game using C# and Unity. Within this game, participants
            assume the role of a Rat endeavoring to navigate an intricate
            laboratory while evading a determined group of pursuing scientists.
            <br></br>
            The game was not designed with browser compatibility in mind,
            resulting in degraded performance when played online.
          </p>
          {/*         <hr className="divider" /> */}
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
};

export default Work;
