import React from "react";
import logo from "../assets/logo.png";
import clickSound from "../assets/logoklick.mp3";
import "../styles/Header.css";

const Header = ({ handleLogoClick }) => {
  const playClickSound = () => {
    const audio = new Audio(clickSound);
    audio.play();
  };

  return (
    <div className="header">
      <img
        src={logo}
        alt="Logo"
        className="logo"
        onClick={() => {
          handleLogoClick();
          playClickSound();
        }}
      />
    </div>
  );
};

export default Header;
