import React, { useState, useEffect, useRef } from "react";
import Header from "./components/Header";
import Navigation from "./components/Navigation";
import About from "./components/About";
import Work from "./components/Work";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
import "./App.css";
import logo from "./assets/logo.png";
import flowerImage2 from "./assets/flower2.png";

const App = () => {
  const [activeNavItem, setActiveNavItem] = useState(null);
  const [showComponents, setShowComponents] = useState(false);
  const [showFlower, setShowFlower] = useState(false);
  const logoRef = useRef(null);

  const handleNavItemClick = (navItem) => {
    setActiveNavItem(navItem);
  };

  const handleLogoClick = () => {
    setActiveNavItem(null);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowComponents(true);
    }, 2000);

    setTimeout(() => {
      setShowFlower(true);
    }, 1000);

    /*   setTimeout(() => {
    set;
  }, 2000); */
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!activeNavItem) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeNavItem]);

  return (
    <div className="App">
      <Header handleLogoClick={handleLogoClick} />
      <div className={`Navigation ${showComponents ? "show" : ""}`}>
        <Navigation
          handleNavItemClick={handleNavItemClick}
          activeNavItem={activeNavItem}
        />
      </div>

      <div className={`content-wrapper ${showComponents ? "show" : ""}`}>
        {showComponents && (
          <>
            {activeNavItem === "about" && <About />}
            {activeNavItem === "work" && <Work />}
            {activeNavItem === "skills" && <Skills />}
            {activeNavItem === "contact" && <Contact />}
          </>
        )}
      </div>
      <div className="content-fade-top"></div>
      {!activeNavItem && showFlower && (
        <>
          <img
            className="flower2 fadeContainer2"
            src={flowerImage2}
            alt="Flower"
          />
        </>
      )}
      <div className="content-fade-bottom"></div>

      <img
        ref={logoRef}
        src={logo}
        alt="Logo"
        style={{ display: "none" }}
        onClick={handleLogoClick}
      />
    </div>
  );
};

export default App;
