/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../styles/Navigation.css";
import clickSound from "../assets/klick.mp3";

const Navigation = ({ handleNavItemClick, activeNavItem }) => {
  const playClickSound = () => {
    const audio = new Audio(clickSound);
    audio.play();
  };

  return (
    <nav className="navigation">
      <ul>
        <li className={activeNavItem === "about" ? "active" : "unactive"}>
          <a
            href="#"
            onClick={() => {
              handleNavItemClick("about");
              playClickSound();
            }}
          >
            <span class="letter1">a</span>
            <span class="letter2">b</span>
            <span class="letter3">o</span>
            <span class="letter4">u</span>
            <span class="letter5">t</span>
          </a>
        </li>
        {/*         <li className={activeNavItem === "services" ? "active" : "unactive"}>
          <a
            href="#"
            onClick={() => {
              handleNavItemClick("services");
              playClickSound();
            }}
          >
            services
          </a>
        </li> */}
        <li className={activeNavItem === "work" ? "active" : "unactive"}>
          <a
            href="#"
            onClick={() => {
              handleNavItemClick("work");
              playClickSound();
            }}
          >
            <span class="letter1">w</span>
            <span class="letter2">o</span>
            <span class="letter3">r</span>
            <span class="letter4">k</span>
          </a>
        </li>
        <li className={activeNavItem === "skills" ? "active" : "unactive"}>
          <a
            href="#"
            onClick={() => {
              handleNavItemClick("skills");
              playClickSound();
            }}
          >
            <span class="letter1">s</span>
            <span class="letter2">k</span>
            <span class="letter3">i</span>
            <span class="letter4">l</span>
            <span class="letter5">l</span>
            <span class="letter6">s</span>
          </a>
        </li>
        <li className={activeNavItem === "contact" ? "active" : "unactive"}>
          <a
            onClick={() => {
              handleNavItemClick("contact");
              playClickSound();
            }}
          >
            <span class="letter1">c</span>
            <span class="letter2">o</span>
            <span class="letter3">n</span>
            <span class="letter4">t</span>
            <span class="letter5">a</span>
            <span class="letter6">c</span>
            <span class="letter7">t</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
