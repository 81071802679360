import React from "react";
import "../styles/About.css";
import pb from "../assets/pb.png";

const About = () => {
  return (
    <div className="about-container">
      <div className="about">
        <h1>
          <span class="tl1">a</span>
          <span class="tl2">b</span>
          <span class="tl3">o</span>
          <span class="tl4">u</span>
          <span class="tl5">t</span>
        </h1>
        <div className="fadeContainer">
          <p>
            I am Lucas, a multimedia artist and developer with a passion for
            creative design. On this page, you will find a curated display of my
            work. I have experience in traditional web technologies such as CSS,
            JavaScript, and HTML.
            <br></br>
            <br></br>
            In addition to my proficiency in web development, I also utilize
            visual programming tools like VVVV and have experience with various
            object-oriented programming languages. Furthermore, I have good
            practice in media creation, including animation, film editing, 3D
            modeling, and music production.
            <br></br>
            <br></br>
            Feel free to explore the showcased projects on this page to gain a
            deeper understanding of my capabilities.
          </p>

          <img className="pb" src={pb} alt="Profile" />
        </div>
      </div>
    </div>
  );
};

export default About;
